import { useEffect, useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

import './transferenciaForm.scss'
import Spinner from '../../../shared/components/Spinner';

export default function TransferenciaForm({
    clienteId,
    numero_presupuesto,
    total,
    onSubmitFile,
    setPaymentStep,
    paymentType
}) {
    const { 
        register,
        watch, 
        formState: { errors }, 
        handleSubmit,  
    } = useForm({
            mode: "onChange",
            defaultValues: {
                entity_id: clienteId,
                comprobante: '',
            }
    });
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setIsLoading(true)

        const comprobante = new FormData();
        comprobante.append('comprobante', data.comprobante[0]);

        await onSubmitFile(comprobante);
        setIsLoading(false)
    };

    const handleCopyText = () => {
        const text = `ES95 2100 3656 7322 0012 3145`;
    
        navigator.clipboard.writeText(text);
        toast.success('Texto copiado!');
      }

    return (
        <div className="w-100 transferencia-form">
            <div className="w-100 h-100 d-flex flex-wrap flex-md-nowrap flex-md-column">
                <div className='transferencia-form__return'>
                    <p onClick={() => setPaymentStep(null)}><FontAwesomeIcon icon={faArrowLeftLong} /> {t('carrito.pago.volver')}</p>
                </div>
                <form className="form h-100 col-12 align-items-center mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <input 
                        type="hidden" 
                        {...register("entity_id")} 
                        defaultValue={clienteId} />

                    <div className="w-100 custom-card p-4">
                        <h4 className="fw-bold text-uppercase">{t('carrito.pago.transferencia.informacion')}</h4>
                        <div>
                            {paymentType === 5
                             ? <>
                                <p className='pb-3'>{t('carrito.pago.efectivo.step1.p1')}</p>
                                <p className='pb-3'>{t('carrito.pago.efectivo.step1.p2')}</p>
                              </>
                             : <p>{t('carrito.pago.transferencia.step1')}</p>
                            }
                        </div>

                        <table className="w-100 mt-3">
                            <tbody>
                                <tr>
                                    <td>{t('carrito.pago.transferencia.titular')}</td>
                                    <td className="text-end">
                                        <b className="account-number" onClick={() => handleCopyText()}>
                                            JG Recomotor SL  
                                        </b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('carrito.pago.transferencia.cuenta')}</td>
                                    <td className="text-end">
                                        <b className="account-number" onClick={() => handleCopyText()}>
                                            ES95 2100 3656 7322 0012 3145 <FontAwesomeIcon icon={faCopy} size="1x" style={{cursor: 'pointer'}} color="#00986C" />  
                                        </b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('carrito.pago.transferencia.concepto')}</td>
                                    <td className="text-end"><b>{numero_presupuesto}</b></td>
                                </tr>
                                <tr>
                                    <td>{t('carrito.pago.transferencia.importe')}</td>
                                    <td className="text-end"><b>{total}€</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="w-100 d-flex flex-column align-items-center mt-5">
                        <div className="form-group">
                            <label htmlFor="comprobante">{paymentType === 5 ? t('carrito.pago.transferencia.comprobante.pago') : t('carrito.pago.transferencia.comprobante.transferencia')}:</label>
                            <input 
                                type="file" 
                                className="form-control"
                                accept="image/jpeg,image/gif,image/png,application/pdf,application/msword,image/x-eps" 
                                {...register("comprobante", { required: true })} 
                                defaultValue={''} />

                            <div className="form-invalid">
                                {(errors.comprobante) && errors.comprobante?.message}
                            </div>
                        </div>

                        <div className="mt-2">
                            <button 
                                type="submit" 
                                className='transferencia-form__btn'
                                disabled={(watch('comprobante') !== '') ? false : true}
                            >{isLoading ? <Spinner className='spinner-transferencia-form'/> : t('carrito.pago.transferencia.enviar')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}