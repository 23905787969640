import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import './checkout.scss';
import { useDispatch, useSelector } from "react-redux";
import { fetchCheckout } from "../../../redux/checkout";
import Loading from "../../shared/components/Loading";
import Navbar from "../../shared/components/Navbar";
import PresupuestoIssue from "./PresupuestoIssue";
import Carrito from "./Carrito";
import VentaSuccess from "./VentaSuccess";

const Checkout = () => {
    const dispatch = useDispatch();
    const { checkoutId } = useParams();
    const checkoutStatus = useSelector(state => {
        return state.checkout.status
    });
    const checkoutInfo = useSelector(state => {
        return state.checkout.entity
    });
    const webSocketChannel = `checkout.${checkoutId}`;

    useEffect(() => {
        dispatch(fetchCheckout(checkoutId));
        //connectWebSocket();

        return () => {
            //window.Echo.leave(webSocketChannel);
        }
    }, []);

   /* const connectWebSocket = () => {
        window.Echo.channel(webSocketChannel)
            .listen('.UpdateCheckout', async (e) => {
                dispatch(fetchCheckout(checkoutId));
            });
    }*/

    return (
        <div className="w-100 h-100" style={{ overflowY: 'auto'}}>
            <Navbar />
            
            { (checkoutStatus.loading === 'succeed' || checkoutStatus.loading === 'rejected') ?
                <div className="container">
                    { ((checkoutInfo.presupuesto.validado && !checkoutInfo.presupuesto.expirado) &&
                        (!checkoutInfo.presupuesto.piezas.find(p => p.integracion_ventas?.length > 0 && p.integracion_ventas?.find(v  => v.id !== checkoutInfo?.is_venta?.id)))
                        ) ?
                            <>
                                { !checkoutInfo.is_venta &&
                                    <Carrito />
                                }

                                { checkoutInfo.is_venta &&
                                    <VentaSuccess 
                                        checkoutId={checkoutId}
                                        checkoutInfo={checkoutInfo}
                                    />
                                }
                            </>
                            : 
                            <PresupuestoIssue
                                checkoutInfo={checkoutInfo}
                            />
                    }
                </div>
                : 
                <div className="container h-100">
                    <Loading />
                </div>
            }
            

            <ToastContainer
                containerId="checkout"
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
            />
        </div>
    )
}

export default Checkout