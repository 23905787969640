import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Navbar from "../shared/components/Navbar"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from 'react-i18next'

const NotFound = () => {
    const { t } = useTranslation()
    return (
        <div className="w-100 h-100 d-flex flex-column px-4 px-md-0" style={{ overflowY: 'scroll'}}>
            <Navbar />

            <div className="container h-100">
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center mb-5">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="action" color="#dc3545" />
                        <h3 className="text-uppercase fw-bold mx-3">{t('not-found')}</h3>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="action" color="#dc3545" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound