import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { checkoutError } from "../../../shared/services/checkout";
import { useTranslation } from 'react-i18next';

const CheckoutError = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = queryString.parse(location.search);
    const  { t } = useTranslation()

    useEffect(() => {
       const errorVenta = async() => {
            const errorResponse = await checkoutError({
                'Ds_SignatureVersion': params.Ds_SignatureVersion,
                'Ds_MerchantParameters': params.Ds_MerchantParameters,
                'Ds_Signature': params.Ds_Signature
            });

            if(errorResponse && errorResponse.success) {
                setTimeout(() => {  
                    navigate('/checkout/' + errorResponse.data, {replace: true});
                }, 3000);
            }
       } 
        
       errorVenta();
    }, []);

    return (
        <div className="dashboard-container pt-5">
            <div className="content">
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="action" color="#dc3545" />
                        <h1 className="m-0 mx-2"><b>{t('checkout-error.title')}</b></h1>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="action" color="#dc3545" />
                    </div>

                    <p className="mt-2">{t('checkout-error.text')}</p>
                </div>
            </div>
        </div>
    )
}

export default CheckoutError