import { toast } from "react-toastify";
import { getEnviosCheckoutStatus } from "../shared/services/checkout";
import { asyncMac, makeAsyncTypes, makeFetchingReducer, makeSetReducer, reduceReducers } from "./utils";
import { useNavigate } from "react-router-dom";

const initialState = {
    envios: []
}

export const envioReducer = (state = initialState, action) => {
    switch(action.type){
        case "envios/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncEnvios = makeAsyncTypes('envios');

const [setPending, setFullFilled, setError ] = asyncMac(asyncEnvios);

export const enviosFetchingReducer = makeFetchingReducer([
    'envios/pending', 
    'envios/fullfilled', 
    'envios/rejected'
]);

const fullfilledReducer = makeSetReducer(['envios/fullfilled']);

export const fetchEnvios = (checkoutId) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getEnviosCheckoutStatus(checkoutId)
        .catch(function (error) {
            toast.error("Ha habido un problema.")
        });
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const enviosReducer = reduceReducers(envioReducer, fullfilledReducer);
export const enviosStatusReducer = enviosFetchingReducer;