import { useRef } from 'react'
import Navbar from "../../shared/components/Navbar"
import './condicionesGenerales.scss'
import { useTranslation } from 'react-i18next'

const CondicionesGenerales = () => {
    const garantiasRef = useRef(null)
    const motoresRef = useRef(null)
    const cajasCambioRef = useRef(null)
    const electricosRef = useRef(null)
    const condicionesRef = useRef(null)
    const plazosRef = useRef(null)
    const danosRef = useRef(null)
    const reservaRef = useRef(null)
    const { t } = useTranslation()

    const scrollToSection = (ref) => {
        if(ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'})
        }
    }
    return (
        <div className="condiciones-generales w-100 h-100 d-flex flex-column px-4" style={{ overflowY: 'scroll'}}>
            <Navbar />

            <div className="w-100 d-flex flex-column flex-lg-row align-items-start justify-content-center condiciones-generales__container">
                <div className='mt-4 condiciones-generales__container--index'>
                    <ul>
                        <li onClick={() => scrollToSection(garantiasRef)}>{t('condiciones-generales.garantias.title')}</li>
                        <ul className='condiciones-generales__container--index--subindex'>
                                <li onClick={() => scrollToSection(motoresRef)}>{t('condiciones-generales.sub-index.1')}</li>
                                <li onClick={() => scrollToSection(cajasCambioRef)}>{t('condiciones-generales.sub-index.2')}</li>
                                <li onClick={() => scrollToSection(electricosRef)}>{t('condiciones-generales.sub-index.3')}</li>
                        </ul>
                        <li onClick={() => scrollToSection(condicionesRef)}>{t('condiciones-generales.devolucion.title')}</li>
                        <li onClick={() => scrollToSection(plazosRef)}>{t('condiciones-generales.plazos-entrega.title')}</li>
                        <li onClick={() => scrollToSection(danosRef)}>{t('condiciones-generales.daños-ocasionados.title')}</li>
                        <li onClick={() => scrollToSection(reservaRef)}>{t('condiciones-generales.reserva.title')}</li>
                    </ul>

                </div>
                <div className="col-12 col-lg-8 content-info mt-4 mb-5 condiciones-generales__container--info">
                    <h1>{t('condiciones-generales.title')}</h1>

                    <h2 ref={garantiasRef}>{t('condiciones-generales.garantias.title')}</h2>
                    <p>{t('condiciones-generales.garantias.text.1')}</p>
                    
                    <p>
                        {t('condiciones-generales.garantias.text.2')}
                        <b> {t('condiciones-generales.garantias.text.3')} </b> 
                        {t('condiciones-generales.garantias.text.4')} 
                        <b> {t('condiciones-generales.garantias.text.5')} </b> 
                        {t('condiciones-generales.garantias.text.6')} <br/><br /> 
                        <b ref={motoresRef}>{t('condiciones-generales.garantias.text.7')} </b> 
                        {t('condiciones-generales.garantias.text.8')}
                        <b> {t('condiciones-generales.garantias.text.9')}</b> <br/><br /> 
                        <b ref={cajasCambioRef}>{t('condiciones-generales.garantias.text.10')} </b> 
                        {t('condiciones-generales.garantias.text.11')} <b>
                        {t('condiciones-generales.garantias.text.12')}</b> <br/><br /> 
                        <b ref={electricosRef}>{t('condiciones-generales.garantias.text.13')}</b>
                        {t('condiciones-generales.garantias.text.14')} 
                        <b> {t('condiciones-generales.garantias.text.15')}</b>
                    </p>

                    <h3 ref={condicionesRef}>{t('condiciones-generales.devolucion.title')}</h3>
                    <p>{t('condiciones-generales.devolucion.text.1')}</p>

                    <p style={{marginTop: '10px'}}>
                        {t('condiciones-generales.devolucion.text.2')} <a href="mailto:info@recomotor.com">info@recomotor.com</a>
                    </p>

                    <p style={{marginTop: '10px'}}>{t('condiciones-generales.devolucion.text.3')}</p>

                    <h3 ref={plazosRef}>{t('condiciones-generales.plazos-entrega.title')}</h3>
                    <p>{t('condiciones-generales.plazos-entrega.text.1')}
                    </p>

                    <h3 ref={danosRef}>{t('condiciones-generales.daños-ocasionados.title')}</h3>
                    <p>{t('condiciones-generales.daños-ocasionados.text.1')}</p>

                    <h3 ref={reservaRef}>{t('condiciones-generales.reserva.title')}</h3>
                    <p>
                        {t('condiciones-generales.reserva.text.1')} 
                        <b> {t('condiciones-generales.reserva.text.2')} </b>
                        {t('condiciones-generales.reserva.text.3')} 
                        <b> {t('condiciones-generales.reserva.text.4')} </b>
                        {t('condiciones-generales.reserva.text.5')}
                    </p>

                    <p className='mt-5'>
                        <b>{t('condiciones-generales.no-conforme')}</b>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CondicionesGenerales