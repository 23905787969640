import { useTranslation } from 'react-i18next'

import './carrito.scss'
import DireccionCard from './DireccionCard'
import { useEffect, useRef, useState } from 'react'
import MetodosPagoList from './MetodosPagoList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faCopy, faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons'
import FormDirEnvio from './FormDirEnvio'
import TotalPedido from './TotalPedido'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNewDireccionCheckout, fetchRecalculatePresupuesto, fetchSaveNotasInfo, fetchSaveSelectedDireccion, fetchShippingPrice, fetchUpdateClienteCheckout, fetchUpdateDireccionCheckout, fetchUpdateDireccionCheckoutEc, setFullFilled } from '../../../../redux/checkout'
import { toast } from 'react-toastify'
import CodigoAutForm from './CodigoAutForm'
import { payWithBizum, payWithCredit, payWithCreditCard, payWithTransfer, payWithoutPayment, updateTransferenciaMethod } from '../../../../shared/services/checkout'
import { setErrorMessage } from '../../../../shared/helpers/functionalities'
import Pago from './Pago'
import ComprovanteTransfEnviado from './ComprovanteTransfEnviado'
import DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import Spinner from './shared/components/Spinner'
import useScreenWidth from '../../../../hooks/useScreenWidth'
import { ConfirmModal } from '../../../shared/components/ConfirmModal'
import Resume from './Resume'
import { wichIVA } from '../../../shared/helpers/functionalities'
import { checkPresupuestoEcommerce } from '../../../../utils'

const Carrito = () => {
  const checkoutInfo = useSelector(state => {
    return state.checkout.entity
  });
  
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const screenWidth = useScreenWidth()
  const formRef = useRef()
  const notasRef = useRef()
  const facturacionRef = useRef()
  const envioRef = useRef()
  const formEnvioRef = useRef()
  const [dirEnvio, setDirEnvio] = useState(null)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [dirFacturacion, setDirFacturacion] = useState({
    nombre: checkoutInfo.cliente.nombre,
    telefono: checkoutInfo.cliente.telefono,
    email: checkoutInfo.cliente.email,
    direccion: checkoutInfo.cliente.direccion_facturacion,
    codigo_postal: checkoutInfo.cliente.codigo_postal_facturacion,
    poblacion: checkoutInfo.cliente.poblacion_facturacion,
    area: checkoutInfo.cliente.area_facturacion,
    pais_id: checkoutInfo.cliente.pais_facturacion_id,
    cif: checkoutInfo.cliente.cif,
    tipo_cliente_id: checkoutInfo.cliente.tipo_cliente_id,
    cif_ue: checkoutInfo.cliente.pais_facturacion_id === 1 ? false : checkoutInfo.cliente.cif_ue,
    b2b: checkoutInfo.cliente.b2b
  })
  
  const [isOpenDirFacturacion, setIsOpenDirFacturacion] = useState(false)
  const [paymentType, setPaymentType] = useState()
  const [paymentForm, setPaymentForm] = useState(null);
  const [paymentStep, setPaymentStep] = useState(null)
  const [isConcidionesChecked, setIsConcidionesChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingSelectDirEnvio, setIsLoadingSelectDirEnvio] = useState(false)
  const [dirsEnvioArray, setDirsEnvioArray] = useState(checkoutInfo.direcciones)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  useEffect(() => {
    if (checkoutInfo.presupuesto.transferencia) {
      return setPaymentType(3)
    } else if(checkoutInfo.presupuesto.efectivo) {
      return setPaymentType(5)
    } else {
      return setPaymentType(1)
    }
  }, [])
  
 
  useEffect(() => {
    if(!dirEnvio) {
      if (checkoutInfo.presupuesto.direccion_envio_id) {
        const direccion = checkoutInfo.direcciones.find(dir => dir.id === checkoutInfo.presupuesto.direccion_envio_id)
        setDirEnvio(direccion)
      } else {
        if (checkoutInfo?.direcciones?.length === 0){
          setDirEnvio('otra')
          setIsOpenForm(true)
        }       
      }
    }
    const isFromEcommerce = checkPresupuestoEcommerce(checkoutInfo?.presupuesto?.created_by?.username)
    if (!isFromEcommerce && checkoutInfo.presupuesto.direccion_envio_id && dirEnvio && dirEnvio !== 'otra' && dirsEnvioArray.length > 0) {
      const direccion = checkoutInfo.direcciones.find(dir => dir.id === checkoutInfo.presupuesto.direccion_envio_id)
      if (direccion?.pais_id !== dirEnvio?.pais_id) {
        setDirEnvio(direccion)
        toast.error(t('errors.diferente'))
      }
    }
  }, [dirEnvio])

  const handleSearchDirsEnvio = (e) => {
    const searchedDirs = checkoutInfo.direcciones.filter(dir => (dir.nombre.toLowerCase()).includes((e.target.value).toLowerCase()) || (dir.direccion.toLowerCase()).includes((e.target.value.toLowerCase())))
    setDirsEnvioArray(searchedDirs)
  }
  
  const handleEdit = (direccion) => {
    if(direccion === 'facturacion') {
      const telf = checkoutInfo.cliente.telefono?.length > 12 ? checkoutInfo.cliente.telefono.split(' ')[0] : checkoutInfo.cliente.telefono;
      const dirFact = {
        nombre: checkoutInfo.cliente.nombre,
        telefono: telf,
        email: checkoutInfo.cliente.email,
        direccion: checkoutInfo.cliente.direccion_facturacion,
        codigo_postal: checkoutInfo.cliente.codigo_postal_facturacion,
        poblacion: checkoutInfo.cliente.poblacion_facturacion,
        area: checkoutInfo.cliente.area_facturacion,
        pais_id: checkoutInfo.cliente.pais_facturacion_id,
      };
      setDirEnvio(dirFact);
    } else {
      setDirEnvio(direccion)
    }
    
    setIsOpenForm(true)
    setTimeout(() => {
      formRef?.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
    }, 1);
  }

  const handleEditSchedule = (e, direccion) => {
    e.stopPropagation()
    if(direccion?.id !== dirEnvio?.id) {
      handleDirEnvio(direccion)
    } else {
      setDirEnvio(direccion)
    }
    setIsOpenForm(true)
    setTimeout(() => {
      formRef.current.lastElementChild.scrollIntoView({ behavior: 'smooth' })
    }, 1);
  }

  const updateDirEnvio = (data) => {
    const isFromEcommerce = checkPresupuestoEcommerce(checkoutInfo?.presupuesto?.created_by?.username)
    isFromEcommerce
      ? updateDirection(data)
      : updateDirectionSchedule(data)
  }

  const updateDirectionSchedule = async(data) => {
    if (checkoutInfo.presupuesto.direccion_envio_id !== data.id) handleDirEnvio(data)
    dispatch(fetchUpdateDireccionCheckout(checkoutInfo.presupuesto.checkout_id, {...data, entity_id: checkoutInfo.cliente.id}))
    const newDirArr = dirsEnvioArray.map(dir => dir.id === data.id ? {...dir, horario_entrega_dia: data.horario_entrega_dia, horario_entrega_tarde: data.horario_entrega_tarde } : dir)
    setDirsEnvioArray(newDirArr)
    setIsOpenForm(false)
    handleSelectDirEnvio()
  }

  const updateDirection = async(data) => {
    const oldAddress = (checkoutInfo?.direcciones).find(address => address?.id === data?.id)
    let address;
    if (checkoutInfo?.presupuesto?.guest_ec) {
      address = await dispatch(fetchUpdateDireccionCheckout(checkoutInfo.presupuesto.checkout_id, {...data, entity_id: checkoutInfo.cliente.id}))
      setDirsEnvioArray([data])
    } else {
      address = await dispatch(fetchUpdateDireccionCheckoutEc(checkoutInfo.presupuesto.checkout_id, {...data, entity_id: checkoutInfo.cliente.id}))
      const newDirArr = dirsEnvioArray.map(dir => dir.id === oldAddress.id ? address.item : dir)
      setDirsEnvioArray(newDirArr)
    }
    if(address?.item.id !== oldAddress?.id || address?.item.codigo_postal !== oldAddress.codigo_postal) handleDirEnvio(address?.item, true)

    setIsOpenForm(false)
    handleSelectDirEnvio()
  }

  const addNewDirection = async (data) => {
    await dispatch(fetchNewDireccionCheckout(checkoutInfo.presupuesto.checkout_id, data))
    const newDir = checkoutInfo.direcciones.reduce((accumulator, currentValue) => {
      return currentValue.id > accumulator.id ? currentValue : accumulator
    }, checkoutInfo.direcciones[0])
    setDirsEnvioArray(checkoutInfo.direcciones)
    handleDirEnvio(newDir)
    setIsOpenForm(false)
    handleSelectDirEnvio()
  }
  
  useEffect(() => {
    const valid = Object.values(dirFacturacion).every(
      value => value !== '' && value !== null && value !== undefined);
    setIsOpenDirFacturacion(!valid)
  }, [dirFacturacion])

  const handleDirFactClient = async(data) => {
      const isFromUserEc = checkPresupuestoEcommerce(checkoutInfo?.presupuesto?.created_by?.username) && !checkoutInfo?.presupuesto?.guest_ec;
      await dispatch(fetchUpdateClienteCheckout(checkoutInfo.presupuesto.checkout_id, {...data, id: checkoutInfo.cliente.id, isFromUserEc}));
      handleCheckIfHasToChangeIva(data.cif_ue)
      setDirFacturacion({
        nombre: data.nombre,
        telefono: data.telefono,
        email: data.email,
        direccion: data.direccion_facturacion,
        codigo_postal: data.codigo_postal_facturacion,
        poblacion: data.poblacion_facturacion,
        area: data.area_facturacion,
        pais_id: data.pais_facturacion_id,
        cif: data.cif,
        tipo_cliente_id: data.tipo_cliente_id,
        cif_ue: data.cif_ue,
        b2b: data.b2b})
      toast.success(t('success-msg.datos-facturacion'));
      if(checkoutInfo.direcciones.length > 0) {
        setDirsEnvioArray(checkoutInfo.direcciones)
        setIsOpenForm(false)
      }
      if(checkPresupuestoEcommerce(checkoutInfo?.presupuesto?.created_by?.username) && checkoutInfo?.presupuesto?.direccion_envio_id) {
        // seur tiene diferentes servicios para b2c i b2b, si ha canviado el tipo cliente también será necesario cambiar el servicio de envío
        checkChangeShippingService(data)
      }
  }

  const checkChangeShippingService = (data) => {
    let shouldChangeShippingService = false;
    for (let servicio of checkoutInfo?.presupuesto?.servicios_envio) {
      if (servicio.servicio_envio_id === 44 && data.b2b) shouldChangeShippingService = true;
      if (servicio.servicio_envio_id === 25 && !data.b2b) shouldChangeShippingService = true;
    }
    if(shouldChangeShippingService) {
      const res = dispatch(fetchShippingPrice(checkoutInfo.presupuesto.id, dirEnvio))
      if (!res) {
        setDirEnvio(null)
        return setIsLoadingSelectDirEnvio(false)
      }
    }
  }

  const handleCheckIfHasToChangeIva = (isCifUe) => {
    const { direccion_envio_id, iva, checkout_id, id } = checkoutInfo.presupuesto
    if(direccion_envio_id) {
      const address = checkoutInfo.cliente.direcciones.find(address => address.id === direccion_envio_id)
      if(wichIVA(dirFacturacion, address, isCifUe) !== iva ) {
        dispatch(fetchRecalculatePresupuesto(id, checkout_id))
      } 
    }
  }

  const checkAllIsChecked = () => {
    const valid =  Object.values(dirFacturacion).every(
      value => value !== '' && value !== null && value !== undefined)
    if(!valid) {
      screenWidth >= 992 ? toast.error(t('errors.facturacion')) : setIsErrorModalOpen('facturacion')
      return false
    }
    
    if(!dirEnvio || dirEnvio === 'otra') {
      screenWidth >= 992 ? toast.error(t('errors.envio')) : setIsErrorModalOpen('envio')
      return false
    }

    if(isOpenForm) {
      screenWidth >= 992 ? toast.error(t('errors.form-abierto')) : setIsErrorModalOpen('form-abierto')
      return false
    }

    return true
  }

  const handleCloseErrorModal = () => {
    if (isErrorModalOpen === 'envio') {
      envioRef.current.scrollIntoView({behavior: 'smooth'})
    } else if (isErrorModalOpen === 'facturacion') {
      facturacionRef.current.scrollIntoView({behavior: 'smooth'})
    } else if (isErrorModalOpen === 'form-abierto') {
      formEnvioRef.current.scrollIntoView({behavior: 'smooth'})
    }
    setIsErrorModalOpen(false)
  }

  const submitOrder = () => {
    const isAllChecked = checkAllIsChecked()
    
    if(!paymentType) {
      return screenWidth >= 992 ? toast.error(t('errors.pago')) : setIsErrorModalOpen('pago')
    } 

    if(isAllChecked && paymentType) {
      if(notasRef.current.value !== checkoutInfo.presupuesto.observaciones_cliente) dispatch(fetchSaveNotasInfo({notas: notasRef.current.value, presupuesto_id: checkoutInfo.presupuesto.id}))
      if(checkoutInfo.presupuesto.total_iva === 0) {
        dispatch(fetchSaveSelectedDireccion(checkoutInfo.presupuesto.checkout_id, dirEnvio.id));
        noPayment();
      } else {
        setIsLoading(true)

        if(paymentType === 1 || paymentType === 2) {
          return checkout();
        }
        
        if(paymentType === 3 || paymentType === 5) return  transferencia();
      }
    }
  }

  const noPayment = async() => {
    setIsLoading(true)
    const response = await payWithoutPayment(checkoutInfo.presupuesto.checkout_id, {
      tipo_pago_id: paymentType
    })
    .catch(function (error) {
        toast.error(setErrorMessage(error))
    });

    if(response && response.success) {
      setIsLoading(false)
      window.location.reload();
    }
  }

  const transferencia = async() => {
    await updateTransferenciaMethod(checkoutInfo.presupuesto.id, {tipo_pago_id: paymentType})
    .catch(function (error) {
      toast.error(setErrorMessage(error))
    });
    setPaymentStep(1)
    return setIsLoading(false)
  }

  const checkout = async() => {
    let response;
    dispatch(fetchSaveSelectedDireccion(checkoutInfo.presupuesto.checkout_id, dirEnvio.id));

    if(paymentType === 1) {
      response = await payWithCreditCard(checkoutInfo.presupuesto.checkout_id, {})
      .catch(function (error) {
        toast.error(setErrorMessage(error))
      });
    }

    if(paymentType === 2) {
      response = await payWithBizum(checkoutInfo.presupuesto.checkout_id, {})
      .catch(function (error) {
        toast.error(setErrorMessage(error))
      });
    }
    
    if(response) {
      setPaymentForm(DOMPurify.sanitize(response));
    }
    
    setPaymentStep(1)
    return setIsLoading(false)
  }

  const handleCreditoPayment = async(data) => {
    const isAllChecked = checkAllIsChecked()
    if(isAllChecked) {
      const response = await payWithCredit(
        checkoutInfo.presupuesto.checkout_id, 
        {
          direccion_envio_id: dirEnvio?.id, 
          codigo_autorizacion: data.codigo_autorizacion
        }
      ).catch(function (error) {
        toast.error(setErrorMessage(error))
      });
  
      if(response && response.success) {
        window.location.reload();
      }
    }
  }

  const handleUploadComprobante = async(data) => {
    data.append('direccion_envio_id', dirEnvio?.id);
    const response = await payWithTransfer(checkoutInfo.presupuesto?.checkout_id, data)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
      });

    if(response && response.success) {
      setPaymentStep(2);
    }
  }

  const handleDirEnvio = async (direccion, forceCalulate) => {
    if(direccion.id === dirEnvio?.id && !forceCalulate) return
    if(isLoadingSelectDirEnvio) return
    setIsLoadingSelectDirEnvio(direccion.id)
    const isFromEcommerce = checkPresupuestoEcommerce(checkoutInfo?.presupuesto?.created_by?.username)
    if(isFromEcommerce) {
      const res = await dispatch(fetchShippingPrice(checkoutInfo.presupuesto.id, direccion))
      if (!res) {
        setDirEnvio(null)
        return setIsLoadingSelectDirEnvio(false)
      }
    } else {
      await dispatch(fetchSaveSelectedDireccion(checkoutInfo.presupuesto.checkout_id, direccion.id));
    }
    setIsLoadingSelectDirEnvio(false)
    setDirEnvio(direccion)
  }

  const handleSelectDirEnvio = () => {
    envioRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleSelectDirFact = () => {
    facturacionRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  
  return <div className='carrito'>
        {!paymentStep && 
          <div>
            <div className='w-100'>
              <section className='carrito__detalle'>
                  <Resume checkoutInfo={checkoutInfo} />
              </section>

              <section className='carrito__facturacion' ref={facturacionRef}>
                  <h2>{t('carrito.facturacion.title')}</h2>
                  {isOpenDirFacturacion ?
                  <FormDirEnvio dirEnvio={dirFacturacion} isDirFact={true} uploadDirection={handleDirFactClient}/>
                  : <DireccionCard
                      direccion={checkoutInfo.cliente}
                      isSelected={true}
                      isDirEnvio={false}
                      handleEditDirFact={() => setIsOpenDirFacturacion(true)}
                  />}
                  {!isOpenDirFacturacion && !checkPresupuestoEcommerce(checkoutInfo?.presupuesto?.created_by?.username) && <p className='carrito__facturacion--info'><span><FontAwesomeIcon icon={faCircleInfo} /></span> {t('carrito.facturacion.cambiar')}</p>}
              </section>

              <section className='carrito__envio' ref={envioRef}>
                  <h2 className='carrito__envio--title'>{t('carrito.envio.title')} {!dirEnvio && checkoutInfo?.direcciones?.length === 0 && <span className='carrito__envio--select-address'>{t('carrito.envio.select-dir')}</span>}</h2>

                  {checkoutInfo.direcciones.length > 6 &&
                    <div className='carrito__envio--search'>
                      <input type='text' onChange={handleSearchDirsEnvio} className='carrito__envio--search--input' placeholder='Escribe la dirección a buscar'/>
                      <span className='carrito__envio--search--icon'><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                    </div>
                  }
                  {dirsEnvioArray?.length > 0 && <div ref={formRef} className='carrito__envio--direcciones'>
                    {dirsEnvioArray?.map(direccion => (
                      <DireccionCard
                          key={direccion.id}
                          direccion={direccion}
                          isSelected={ dirEnvio?.id === direccion.id }
                          setDirEnvio={handleDirEnvio}
                          setIsOpenForm={setIsOpenForm}
                          handleEditSchedule={handleEditSchedule}
                          isOutOfRange={false}
                          isLoadingSelectDirEnvio={isLoadingSelectDirEnvio} 
                        />
                    ))}
                  </div>}

                  {(((checkoutInfo.direcciones.length === 0 && !isOpenDirFacturacion)) || (checkoutInfo.direcciones.length > 0)) && <div className='carrito__envio--add-address'>
                      { (checkoutInfo.direcciones.length === 0 && !isOpenDirFacturacion && dirsEnvioArray.length === 0) && 
                        <button className='carrito__envio--add-address--btn me-3' onClick={() => handleEdit('facturacion')}><span><FontAwesomeIcon icon={faCopy} /></span>{t('carrito.direccion.copiar-facturacion')}</button>
                      }
                      { checkoutInfo.direcciones.length > 0 && !checkoutInfo?.presupuesto?.guest_ec && <button className='carrito__envio--add-address--btn' onClick={() => handleEdit('otra')}><span><FontAwesomeIcon icon={faPlus} /></span>{t('carrito.direccion.nueva')}</button>}
                  </div>}
                  { (isOpenForm  || checkoutInfo.direcciones.length === 0) &&
                    <FormDirEnvio
                        formEnvioRef={formEnvioRef}
                        dirEnvio={dirEnvio}
                        addNewDirection={addNewDirection}
                        uploadDirection={updateDirEnvio}
                        isOpenDirFacturacion={isOpenDirFacturacion}
                        handleSelectDirFact={handleSelectDirFact}
                      />
                  }
              </section>
            </div>

            <section className='carrito__pedido'>
                <div>
                    <TotalPedido handleSelectDirEnvio={handleSelectDirEnvio}/>
                    {checkoutInfo.cliente.credito 
                      ? <CodigoAutForm
                          conCodigo={checkoutInfo.cliente.con_codigo === 0 ? false : true}
                          presupuesto={checkoutInfo.presupuesto}
                          onSubmitCodigoAut={handleCreditoPayment} 
                          /> 
                      : <>
                          <MetodosPagoList checkoutInfo={checkoutInfo} paymentType={paymentType} setPaymentType={setPaymentType} />
                          <div className='carrito__pedido--textarea'>
                              <textarea id='notas' ref={notasRef} defaultValue={checkoutInfo.presupuesto.observaciones_cliente} placeholder={t('carrito.pedido.notas')} />
                          </div>
                          <div className='carrito__pedido--submit'>
                              <div>
                                  <input type='checkbox' checked={isConcidionesChecked} onChange={(e) => setIsConcidionesChecked(e.target.checked)}/>
                                  <p>
                                      {t('carrito.pedido.condiciones.1')}
                                      <Link to={"/condiciones"} title="Condiciones Generales de Compra" target={"_blank"}><b> {t('carrito.pedido.condiciones.3')}</b></Link>
                                  </p>
                              </div>
                              <button disabled={!isConcidionesChecked} onClick={submitOrder}>{isLoading ? <Spinner className='spinner-pedido-section'/> : t('carrito.pedido.submit')}</button>
                          </div>
                        </>}
              </div>
            </section>
          </div>
        }
        {isErrorModalOpen && <ConfirmModal text={t(`errors.${isErrorModalOpen}`)} handleCloseErrorModal={handleCloseErrorModal} />}
        {paymentStep === 1 && <Pago 
              checkoutInfo={checkoutInfo} 
              paymentType={paymentType}
              paymentForm={paymentForm}
              setPaymentStep={setPaymentStep}
              onUploadComprobante={handleUploadComprobante} />}
        {paymentStep === 2 && <ComprovanteTransfEnviado checkoutInfo={checkoutInfo} />}
    </div>
}

export default Carrito
