import { useState } from 'react';
import './codigoDescuento.scss'
import { toast } from 'react-toastify';
import { fetchApplyDiscount } from '../../../../../../../redux/checkout';
import { useDispatch } from 'react-redux';
import Spinner from '../../../shared/components/Spinner';
import { useTranslation } from 'react-i18next';

const CodigoDescuento = ({checkoutInfo}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [codigoDescuento, setCodigoDescuento] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenCodigoDescuento, setIsOpenCodigoDescuento] = useState(false)

  const handleAddCodigoDescuento = async(codigoDescuentoId) => {
    dispatch(fetchApplyDiscount(checkoutInfo.presupuesto.checkout_id, codigoDescuentoId));
  }
  
  const handleChangeCodigo = (e) => { setCodigoDescuento(e.target.value); }

  const handleCheckCodigo = () => {
    setIsLoading(true)
    const isCodigo = checkoutInfo.cliente.vales_descuentos?.find(vale => vale.codigo === codigoDescuento);

    if(isCodigo) {
      if(!isCodigo.terminado) {
        handleAddCodigoDescuento(isCodigo.id);
      } else {
        toast.error(t('carrito.codigo-descuento.error.1'))
      }
    } else {
      toast.error(t('carrito.codigo-descuento.error.2'))
    }
  }

  return (
    <>{ (checkoutInfo.presupuesto?.vale_descuento === null) 
      ?
      <>
        
          <div className='codigo-descuento'>
            <p className='codigo-descuento__title' onClick={() => setIsOpenCodigoDescuento(!isOpenCodigoDescuento)}>{t('carrito.codigo-descuento.text')}</p>
            { (checkoutInfo.cliente?.vales_descuentos?.length > 0) &&
              <div className='w-100 d-flex flex-row align-items-center justify-content-start pt-1 pb-2 descuentos-cuenta'>

              <div className='d-flex align-items-center justify-content-between ms-2'>
                { checkoutInfo.cliente?.vales_descuentos?.map(descuento => {
                  return (
                    <div key={descuento.id} className='d-flex align-items-center pill primaryLight'>
                      <span className='fw-bold'>{descuento.codigo} </span> 
                      <span className='ms-2'>{(descuento.total - descuento.gastado).toFixed(2)}€</span>
                    </div>
                  )
                })}
              </div>
              </div>
            }
            {isOpenCodigoDescuento && <div className='codigo-descuento__container-input-btn'>
              <input
                type='text' 
                name="descuento" 
                value={codigoDescuento} 
                onChange={(e) => handleChangeCodigo(e)}
              />
              <button 
                type='button' 
                onClick={() => !isLoading && handleCheckCodigo()}>
                  {isLoading ? <Spinner className='spinner-codigo-descuento'/> : t('carrito.codigo-descuento.aplicar')}
                </button>
            </div>}
          </div>
   
      </>
      : <p className='pb-2'><span>{t('carrito.codigo-descuento.descuento')} <span className='codigo-descuento__aplicado'>{checkoutInfo.presupuesto?.vale_descuento?.codigo}</span></span><span>- {checkoutInfo.presupuesto.total_descuento_iva}€</span></p>
    }
  </>
  )
}

export default CodigoDescuento
