import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditBtn = ({className = '', direccion = null, handleEdit}) => {
    return (
        <div
            className={`direccion-card__editable ${className}`}
            onClick={(e) => handleEdit(e, direccion)}
        >
            <span>
                <FontAwesomeIcon icon={faPenToSquare} />
            </span>
        </div>
    );
};

export default EditBtn;
