import { Route, Routes } from "react-router-dom";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import Home from "./views";
import './styles/main.scss';
//import Echo from 'laravel-echo';
//import Pusher from 'pusher-js';

function App() {
  /*window.Pusher = Pusher;
  window.Echo = new Echo({
    broadcaster: 'reverb',
    key: process.env.REACT_APP_REVERB_APP_KEY,
    wsHost: process.env.REACT_APP_REVERB_HOST,
    wsPort: process.env.REACT_APP_REVERB_PORT ?? 80,
    wssPort: process.env.REACT_APP_REVERB_PORT ?? 443,
    forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    apiOriginAllow: {
      allowCors: true,
      allowOrigin: "*",
      allowMethods: "GET,POST",
      allowHeaders: "Origin, Content-Type, X-Auth-Token, X-Requested-With, Accept, Authorization, X-CSRF-TOKEN, X-Socket-Id"
    }
  });*/

  return (
    <Routes>
      <Route path="*" element={<Home />}></Route>
    </Routes>
  );
}

export default App;
