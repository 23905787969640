import moment from "moment";

export const wichIVA = (dirFacturacion, dirEnvio, cifUE) => {
  let IVA = 21;

  if(dirFacturacion.pais_id === 1) {                    // ESPAÑA
    if(isCanariasCeutaOrMelilla(dirFacturacion.codigo_postal)) {
      if(dirEnvio.pais_id === 1) { 
        if(isCanariasCeutaOrMelilla(dirEnvio.codigo_postal)) {
            IVA = 0;
        } else  IVA = 21;
      } else IVA = 0;
    } else { IVA = 21; }
  } else {                                              // FUERA DE ESPAÑA
    if(cifUE) {
      if(dirEnvio.pais_id === 1) { 
        if(isCanariasCeutaOrMelilla(dirEnvio.codigo_postal)) {
          IVA = 0;
        } else  IVA = 21;
      } else IVA = 0;
    } else {
      IVA = 21;
    }
  }
  
  return IVA;
}

export const isCanariasCeutaOrMelillaOrBaleares = (postalCode) => {
  return (isCanariasCeutaOrMelilla(postalCode) || (postalCode >= 7001 && postalCode <= 7872)) ? true : false;
}

export const isCanariasCeutaOrMelilla = (postalCode) => {
  return (postalCode >= 51001 && postalCode <= 51080) ||      //CEUTA                             
  (postalCode >= 52001 && postalCode <= 52080 ) ||          // MELILLA
  (                                                         // CANARIAS
    (postalCode >= 35001 && postalCode <= 35660) ||
    (postalCode >= 38001 && postalCode <= 38917)
  )      
  ? false : true;
}

export const isAddressOutOfRange = (postalCode) => {
  if ((postalCode >= '51001' && postalCode <= '51080') || // CEUTA
  (postalCode >= '52001' && postalCode <= '52080') || // MELILLA
  ((postalCode >= '35001' && postalCode <= '35660') || (postalCode >= '38001' && postalCode <= '38917')) || // CANARIAS
  ((postalCode >= '9000-001' && postalCode <= '9980-999')) // ISLAS PORTUGAL
  ) {
    return true
  } else {
    return false
  }
}

export const isOnVacation = (proveedor) => {
    if(proveedor?.vacaciones?.length > 0) {
        const currDay = moment();

        let isInVacation = proveedor?.vacaciones.find(vacaciones => {
            const startDate = moment(vacaciones.fecha_inicio);
            const endDate = moment(vacaciones.fecha_fin);

            if(currDay.isBetween(startDate, endDate, 'days', '[]')) {
              return true;
            } else return false;
        });

        return isInVacation ? true : false;
      } else { return false; }
}