import { useEffect } from "react";
import React from "react";

export default function RedsysForm({form}) {
    useEffect(() => {
        if (form) {
            if (document.forms['realizarPago']) {
                document.forms['realizarPago'].submit();
            }
        }
    });

    return <div id="redsys-payment" dangerouslySetInnerHTML={{__html: form}}></div>;
}