import { useTranslation } from 'react-i18next'
import Navbar from "../../shared/components/Navbar"

const AvisoLegal = () => {
    const { t } = useTranslation()
    return (
        <div className="w-100 h-100 d-flex flex-column px-4 px-md-0" style={{ overflowY: 'scroll'}}>
            <Navbar />

            <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="col-12 col-md-8 col-lg-6 content-info mt-4 mb-5">
                    <h1>{t('aviso-legal.ley.title')}</h1>
                    <p>{t('aviso-legal.ley.text.1')}</p>    
                    <p>{t('aviso-legal.ley.text.2')}</p>

                    <h1 className='mt-3'>{t('aviso-legal.datos.title')}</h1>

                    <div>
                        <p><b>{t('aviso-legal.datos.text.1')}</b> www.recomotor.com</p>
                        <p><b>{t('aviso-legal.datos.text.2')}</b> Recomotor</p>
                        <p><b>{t('aviso-legal.datos.text.3')}</b> JG RECOMOTOR S.L.</p>
                        <p><b>{t('aviso-legal.datos.text.4')}</b> B04950093</p>
                        <p><b>{t('aviso-legal.datos.text.5')}</b> Travessera de Gracia 18, 082021 Barcelona</p>
                        <p><b>{t('aviso-legal.datos.text.6')}</b> +34 644 175 425</p>
                        <p><b>{t('aviso-legal.datos.text.7')}</b> info@recomotor.com</p>

                        <p className="mt-2">{t('aviso-legal.datos.text.8')}</p>
                    </div>

                    <h1 className='mt-3'>{t('aviso-legal.derechos.title')}</h1>
                    <p>{t('aviso-legal.derechos.text')}</p>

                    <h1 className='mt-3'>{t('aviso-legal.exencion.title')}</h1>
                    <p>{t('aviso-legal.exencion.text')}</p>

                    <h2>{t('aviso-legal.exencion.subtitles.cookies.title')}</h2>
                    <p>{t('aviso-legal.exencion.subtitles.cookies.text')}</p>

                    <h2>{t('aviso-legal.exencion.subtitles.enlaces.title')}</h2>
                    <p>{t('aviso-legal.exencion.subtitles.enlaces.text')}</p>

                    <h2>{t('aviso-legal.exencion.subtitles.ip.title')}</h2>
                    <p>{t('aviso-legal.exencion.subtitles.ip.text')}</p>

                    <h1 className='mt-3'>{t('aviso-legal.jurisdiccion.title')}</h1>
                    <p>{t('aviso-legal.jurisdiccion.text')}</p>
                </div>
            </div>
        </div>
    )
}

export default AvisoLegal