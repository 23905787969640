import API from './api';
import { headers } from '../helpers/functionalities';

export const getCheckoutInfo = async (checkoutId) => {
    return API.get('/checkout/' + checkoutId).then(res => res.data);
}

export const updateTransferenciaMethod = async (presupuestoId, presupuesto) => {
    return API.post('/checkout/' + presupuestoId + '/transferencia', presupuesto, headers).then(res => res.data);
}

export const seachPoblacionesCheckout = async (idPais, search) => {
    return await API.get('/checkout/paises/poblaciones/' + idPais + "?search=" + search).then(res => res.data);
}

export const updateClienteCheckout = async (checkoutId, data) => {
    return API.post('/checkout/' + checkoutId + '/update/cliente', data).then(res => res.data);
}

export const newDireccionClienteCheckout = async (checkoutId, data) => {
    return API.post('/checkout/' + checkoutId + '/new/direccion', data).then(res => res.data);
}

export const updateDireccionClienteCheckout = async (checkoutId, data) => {
    return API.post('/checkout/' + checkoutId + '/update/direccion', data).then(res => res.data);
}

export const updateDireccionClienteCheckoutEc = async (checkoutId, data) => {
    return API.post('/checkout/' + checkoutId + '/update/direccion-ec', data).then(res => res.data);
}

export const saveSelectedDireccionCheckout = async (checkoutId, data) => {
    return API.post('/checkout/' + checkoutId + '/save-direccion', data).then(res => res.data);
}

export const aplicarDescuentoCheckout = async (checkoutId, data) => {
    return API.post('/checkout/' + checkoutId + '/descuento', data).then(res => res.data);
}

export const payWithCreditCard = async (checkoutId, data) => {
    return API.post('/checkout-cc-payment/' + checkoutId, data).then(res => res.data);
}

export const payWithBizum = async (checkoutId, data) => {
    return API.post('/checkout-bizum-payment/' + checkoutId, data).then(res => res.data);
}

export const payWithTransfer = async (checkoutId, data) => {
    return API.post('/checkout-transfer-payment/' + checkoutId, data, headers).then(res => res.data);
}

export const payWithCredit = async (checkoutId, data) => {
    return API.post('/checkout-credit-payment/' + checkoutId, data).then(res => res.data);
}

export const payWithoutPayment = async (checkoutId, data) => {
    return API.post('/checkout-no-payment/' + checkoutId, data).then(res => res.data);
}

export const checkPurchaseDone = async (data) => {
    return API.post('/check-purchase', data).then(res => res.data);
}

export const transferCheckoutDone = async (checkoutId, data) => {
    return API.post('/checkout-transfer-done/' + checkoutId, data).then(res => res.data);
}

export const transferCheckoutDenied = async (checkoutId, data) => {
    return API.post('/checkout-transfer-denied/' + checkoutId, data).then(res => res.data);
}

export const checkoutError = async (data) => {
    return API.post('/checkout-error', data).then(res => res.data);
}

export const getEnviosCheckoutStatus = async (checkoutId) => {
    return API.get('/checkout/' + checkoutId + '/seguimiento').then(res => res.data);
}

export const getShippingPrice = async (data) => {
    return API.post('/checkout/shippingservice', data).then(res => res.data)
}

export const checkIntracommunityNIF = async (data) => {
    return API.post('/checkout/intra-community', data).then(res => res.data).catch(error => error.message)
}

export const recalculatePresupuesto = async (data) => {
    return API.post('/checkout/recalculate-presupuesto', data).then(res => res.data);
}

export const saveNotasInfo = async (data) => {
    return API.post('/checkout/save-notas-info', data).then(res => res.data);
}