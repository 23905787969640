import { combineReducers } from "redux";
import { checkoutReducer, checkoutStatusReducer } from "./redux/checkout";
import { enviosReducer, enviosStatusReducer } from "./redux/envios";

const reducer = combineReducers({
    checkout: combineReducers({
        entity: checkoutReducer,
        status: checkoutStatusReducer
    }),
    envios: combineReducers({
        entity: enviosReducer,
        status: enviosStatusReducer
    }),
})

const rootReducer = reducer;

export default rootReducer;