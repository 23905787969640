import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DeliverySchedule = ({ direccion, setValue }) => {
    const { t } = useTranslation();
    const [scheduleState, setScheduleState] = useState({
        morning: false,
        afternoon: false,
        both: false
    });

    const handleSchedule = (e) => {
        setScheduleState({
            morning: e.target.name === 'morning',
            afternoon: e.target.name === 'afternoon',
            both: e.target.name === 'both',
        });
        if (e.target.name === 'morning') {
            setValue('horario_entrega_dia', '08:00-14:00');
            setValue('horario_entrega_tarde', null);
        } else if (e.target.name === 'afternoon') {
            setValue('horario_entrega_dia', null);
            setValue('horario_entrega_tarde', '14:00-20:00');
        } else {
            setValue('horario_entrega_dia', '08:00-14:00');
            setValue('horario_entrega_tarde', '14:00-20:00');
        }
    };
    useEffect(() => {
        setScheduleState({
            morning: (direccion?.horario_entrega_dia && !direccion?.horario_entrega_tarde) || false,
            afternoon: (!direccion?.horario_entrega_dia && direccion?.horario_entrega_tarde) || false,
            both: (direccion?.horario_entrega_dia && direccion?.horario_entrega_tarde) || false
        });
    }, [direccion])
    
    return (
        <div className="delivery-schedule">
            <h4>{t('carrito.direccion.pref-entrega')}</h4>
            <div className='delivery-schedule__container'>
                {Object.keys(scheduleState).map((name) => (
                    <label key={name}>
                        <input
                            type="checkbox"
                            name={name}
                            checked={scheduleState[name]}
                            onChange={handleSchedule}
                        />
                        {t(`carrito.envio.form.schedule.${name}`)}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default DeliverySchedule;
