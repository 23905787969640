import PropTypes from 'prop-types'

const InputPaises = ({ value, onChange, paises, disabled}) => {
  return (
    <select value={value} onChange={onChange} disabled={disabled}>
      {paises.map(pais => <option key={pais.id} value={pais.id}>{pais.nombre}</option>)}
    </select>
  )
}

InputPaises.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  paises: PropTypes.array.isRequired,
  disabled: PropTypes.any
}

export default InputPaises
