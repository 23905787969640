import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

import './confirmModal.scss'

export const ConfirmModal = ({ text, handleCloseErrorModal }) => {
  const [delayOpen, setDelayOpen] = useState(false)

  useEffect(() => {
    setDelayOpen(true)
  }, [])

  return (
        <>
            <div className={delayOpen ? 'confirm show' : 'confirm'}>
                <div className="confirm-content">
                    <span className='confirm-content__icon'><FontAwesomeIcon icon={faTriangleExclamation} /></span>
                    <p className='confirm-content__text'>{text}</p>
                </div>
                <div className="confirm-btns">
                    <button className='confirm-btns__confirm' onClick={() => handleCloseErrorModal()}>OK</button>
                </div>
            </div>
            <div
                className="overlay"
                onClick={() => handleCloseErrorModal()}
            />
        </>
  )
}