import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import moment from 'moment';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const steps = ['Pagado', 'En preparación', 'Enviado', 'Entregado'];

export default function CustomStepper({
    step,
    envio
}) {

    useEffect(() => {
        
    }, [step]);

    const getDate = (step) => {
      switch (step) {
        case 0: 
          return moment(envio.fecha).format('DD-MM-YYYY');
        case 1:
          return getStatus('enviado');
        case 2:
          return getStatus('en-camino');
        case 3:
            return getStatus('entregado');
        default: 
          return ''
      }
    }

    const getStatus = (type) => {
      const statusList = envio.estado;
      let statusDate = null;

      if(type === 'enviado') {
        statusDate = statusList.find(s => 
          // SEUR
          s.codigo === 'GL001' || 
          s.codigo === 'LI567' ||
          s.codigo === 'SX001' ||
          // MRW
          s.codigo === '17'
        )?.fecha;
      } else if(type === 'en-camino') {
        statusDate = statusList.find(s => 
          //SEUR
          s.codigo === 'LO001' ||
          s.codigo === 'LC003' ||
          // MRW
          s.codigo === '57' ||
          s.codigo === '58' || 
          s.codigo === '59' || 
          s.codigo === '79' || 
          s.codigo === '16'
        )?.fecha
      } else if(type === 'entregado') {
        statusDate = statusList.find(s => 
          s.codigo === 'LL003' || s.codigo === 'LL001' || 
          s.codigo === '00'
        )?.fecha
      }
      
      
      return statusDate ? moment(new Date(statusDate)).format('DD-MM-YYYY') : ''
    }

    return (
        <Stepper alternativeLabel activeStep={step} connector={<QontoConnector />}>
            {steps.map((label, i) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <div className='d-flex flex-column'>
                    <small>{label}</small>
                    <small>{getDate(i)}</small>
                  </div>
                </StepLabel>
              </Step>
            ))}
        </Stepper>
    );
}
