import { useEffect, useState } from 'react';
import imgDefault from '../../../../../../images/carrito/placeholder.jpg';
import { checkPresupuestoEcommerce } from '../../../../../../utils';
import { useSelector } from 'react-redux';

const Image = ({ json, className = '' }) => {
  const checkoutInfo = useSelector(state => {
    return state.checkout.entity
  });
  
  const [foto, setFoto] = useState('')
  const [isImgDefault, setIsImgDefault] = useState(false)

  useEffect(() => {
    if (json && checkPresupuestoEcommerce(checkoutInfo?.presupuesto?.created_by?.username)) {
      const jsonDecoded = JSON.parse(json)
      const img = (jsonDecoded?.imgs?.split(',')[0] || '')
      return setFoto(img.startsWith('http') ? img : `https://d2g2xclmj7afe.cloudfront.net/${img}`)
    }
    return setFoto(imgDefault);
  }, [])
  
  return (
    <img
      src={foto}
      onError={(e) => {
        setIsImgDefault(true)
        e.target.src = imgDefault
      }}
      className={`${className} ${isImgDefault ? 'img-default' : ''}`}
      alt='img'
    />
  )
}

export default Image

